import React, { useState } from 'react'
import styled from 'styled-components'

// Components
import ImageBackground from 'components/shared/ImageBackground'
import ParseContent from 'components/shared/ParseContent'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'

// Images
import BackImg1 from 'img/back1.png'
import BackImg2 from 'img/back2.png'
import BackImg3 from 'img/back3.png'
import IconUp from 'img/icon-up.svg'
import IconDown from 'img/icon-down.svg'

const BackImgs = [BackImg3, BackImg2, BackImg1]

export default function Innovation({ fields, data }) {
  const [active, setActive] = useState(2)

  return (
    <Background img={BackImgs[active]}>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 pt-lg-0 pt-5 d-flex justify-content-between flex-wrap">
            {fields.halls.map(({ title, items }, index) => {
              return (
                <DropDowns
                  key={title}
                  title={title}
                  items={items}
                  expanded={index === active}
                  onExpand={() => setActive(index)}
                />
              )
            })}
          </div>
          <div className="col-lg-5 pt-lg-0 pt-5 pl-4 position-static">
            <Content content={fields.description} />
            <div className="mt-5 pt-lg-5 pb-5 pb-lg-0 d-flex justify-content-lg-end justify-content-center">
              <ButtonDefault to="/innovation-lab">Meer over innovatie</ButtonDefault>
            </div>
          </div>
        </div>
      </div>
    </Background>
  )
}
const Background = styled.div`
  background: 'white';
  padding-top: 150px;
  padding-bottom: 200px;
  background: url(${(props) => props.img}) no-repeat bottom left;

  @media (max-width: 1850px){
    background-size: 80% !important;
  }

  @media screen and (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 200px;
    background-size: 100% !important;
  }
`

const Content = styled(ParseContent)`
  h2 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-weight: ${(props) => props.theme.font.weight.l};
    font-size: ${(props) => props.theme.font.size.xxml};
    line-height: ${(props) => props.theme.font.size.xxl};
    padding-bottom: 40px;
    margin-bottom: 50px;
    line-height: 65px;
    position: relative;
    white-space: nowrap;

    @media screen and (max-width: 576px) {
      font-size: ${props => props.theme.font.size.xxl};
      padding-bottom: 20px;
      margin-bottom: 40px;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 2000px;
      height: 5px;
      background-color: ${props => props.theme.color.face.main};
    }
  }

  p {
    line-height: 30px;
  }
`
const RedLine = styled.div`
  position: absolute;
  height: 10px;
  border-bottom: 5px solid ${(props) => props.theme.color.face.main};
  width: 100%;
  @media screen and (max-width: 576px) {
    width: calc(100% - 30px);
  }
`

const DropDowns = ({ title, items, expanded, onExpand }) => {
  return (
    <DropDownWrapper className="position-relative">
      <DropDownContainer expanded={expanded}>
        <h5 className="mb-4">{title}</h5>
        {items.map(({ title }, index) => {
          return (
            <div className="d-flex mx-1 my-2" key={title}>
              <CircleNumber>{`${index + 1}.`}</CircleNumber>
              <span className="ml-2">{title}</span>
            </div>
          )
        })}
      </DropDownContainer>
      <ArrowButton onClick={() => onExpand()}>
        {expanded ? (
          <img src={IconUp} alt="arrow" />
        ) : (
          <img src={IconDown} alt="arrow" />
        )}
      </ArrowButton>
    </DropDownWrapper>
  )
}
const DropDownWrapper = styled.div`
  @media screen and (max-width: 768px) {
    margin: 0 auto 50px auto;
    width: 240px;
  }
`
const DropDownContainer = styled.div`
  color: ${(props) => props.theme.color.text.secondary};
  border: 1px solid ${(props) => props.theme.color.face.main};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  font-size: ${(props) => props.theme.font.size.s};
  line-height: ${(props) => props.theme.font.size.xm};
  padding: 20px;
  height: fit-content;
  max-height: ${(props) => (props.expanded ? '300px' : '65px')};
  overflow: hidden;
  transition: all 300ms ease;
  background: white;
  h5 {
    font-size: ${(props) => props.theme.font.size.m};
    font-weight: ${(props) => props.theme.font.weight.l};
  }
`
const CircleNumber = styled.div`
  width: ${(props) => props.theme.font.size.xm};
  height: ${(props) => props.theme.font.size.xm};
  border: 1px solid ${(props) => props.theme.color.face.main};
  color: ${(props) => props.theme.color.face.main};
  font-size: ${(props) => props.theme.font.size.s};
  line-height: ${(props) => props.theme.font.size.xm};
  font-weight: ${(props) => props.theme.font.weight.l};
  text-align: center;
  border-radius: 50%;
`
const ArrowButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.color.face.main};
  border-radius: 50%;
  position: absolute;
  margin-top: -20px;
  background: white;
  margin-left: calc(50% - 20px);
  img {
    margin: auto;
  }
`
