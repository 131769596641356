import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import Service from 'components/elements/Service'

// Third Party
import styled from 'styled-components'

const StyledServices = styled.div``

const Services = ({ className }) => {
  const {
    services: {
      nodes: services
    }
  } = useStaticQuery(graphql`{
    services: allWordpressPage(filter: {wordpress_parent: {eq: 117}}) {
      nodes {
        ...ServicesFrag
      }
    }
  }`)

  return (
    <StyledServices className={`d-flex flex-wrap justify-content-between ${className ? `${className}` : ``}`}>
      {services.map((service) => {
        return <Service key={service.title} {...service} />
      })}
    </StyledServices>
  )
}

export default Services