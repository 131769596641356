import React from 'react'

// Third Party
import styled from 'styled-components'

// Images
import BackImg from 'img/back8.png'
import BikeImg from 'img/busbiker-new.png'

// Components
import ImageBackground from 'components/shared/ImageBackground'
import ParseContent from 'components/shared/ParseContent'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'

export default function BusBiker({ fields }) {
  return (
    <Background src={BackImg} style={{ backgroundSize: '100% 100%' }}>
      <div className="container d-flex flex-wrap pb-5">
        <ContentContainer className="col-lg-6 ">
          <Content content={fields.description} />
        </ContentContainer>
        <BikeImage
          className="col-lg-6"
          src={BikeImg}
          style={{ backgroundSize: 'contain', height: 500 }}
        ></BikeImage>
      </div>
      <div className="d-flex justify-content-center">
        <ButtonDefault to="/showcase/busbiker">Bekijk showcase</ButtonDefault>
      </div>
    </Background>
  )
}
const ContentContainer = styled.div`
  margin-top: 270px;
  @media screen and (max-width: 992px) {
    margin-top: 460px;
  }
`

const Content = styled(ParseContent)`
  color: ${props => props.theme.color.text.light};

  h2 {
    font-size: ${(props) => props.theme.font.size.xxl};
    font-family: ${(props) => props.theme.font.family.secondary};
    margin-bottom: 20px;
  }

  p {
    font-weight: ${props => props.theme.font.weight.s};
    line-height: 30px;
  }
`

const Background = styled(ImageBackground)`
  position: relative;
  margin-top: -250px;
  @media screen and (max-width: 992px) {
    background-size: cover !important;
    &:before {
      background-size: cover !important;
    }
  }
`
const BikeImage = styled(ImageBackground)`
  height: 500px;

  @media screen and (max-width: 992px) {
    height: 300px !important;
    &:before {
      height: 300px !important;
    }
  }
`
