import React from 'react'

// Third Party
import styled from 'styled-components'

// Components
import ImageBackground from 'components/shared/ImageBackground'
import ParseContent from 'components/shared/ParseContent'
import CustomLink from 'components/shared/CustomLink'

// Images
import BackImg from 'img/hero8.png'
import ArrowIcon from 'img/icon-right.svg'

export default function MoreAbout({ data }) {
  return (
    <Container src={BackImg} style={{ backgroundSize: 'cover' }}>
      <div className="container d-flex flex-row-reverse">
        <div className="col-lg-6 mt-lg-n5">
          {data.map(({ title, description }, index) => {
            return (
              <Item className="d-flex mb-4 pb-3w" index={index} key={title}>
                <div className="flex-column justify-content-start pt-2">
                  <img src={ArrowIcon} alt="arrow" />
                </div>
                <div className="ml-3">
                  <Description>
                    {<Content content={description} />}
                  </Description>
                </div>
              </Item>
            )
          })}
          <Item className="mt-5 d-flex" index={data.length}>
            <Button to="/over-vmt">Meer over VMT</Button>
          </Item>
        </div>
      </div>
    </Container>
  )
}

const Container = styled(ImageBackground)`
  padding: 150px 0 60px 0;
  color: ${(props) => props.theme.color.text.light};
`
const Content = styled(ParseContent)`
  h2 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-weight: ${(props) => props.theme.font.weight.m};
    font-size: ${(props) => props.theme.font.size.xxl};
    line-height: ${(props) => props.theme.font.size.xxl};
  }
`
const Description = styled.div`
  font-family: ${(props) => props.theme.font.family.main};
  font-size: ${(props) => props.theme.font.size.sm};
  line-height: ${(props) => props.theme.font.size.m};
  font-weight: ${(props) => props.theme.font.weight.m};
`
const Button = styled(CustomLink)`
  font-family: ${(props) => props.theme.font.family.main};
  font-size: ${(props) => props.theme.font.size.m};
  line-height: ${(props) => props.theme.font.size.xl};
  font-weight: ${(props) => props.theme.font.weight.xl};
  color: ${(props) => props.theme.color.text.light};
  padding: 18px 48px;
  border: 2px solid white;
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  transition: all 300ms ease;
  text-transform: none;
  &:hover {
    background: white;
    color: black !important;
    text-decoration: none;
  }
  @media screen and (max-width: 768px) {
    margin: 0 auto;
  }
`
const Item = styled.div`
  margin-left: ${(props) => props.index * 40}px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`
