/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'

// Elements
import HeroDefault from 'components/elements/HeroDefault'
import MoreAbout from 'components/elements/MoreAbout'
import Innovation from 'components/elements/Innovation'
import BusBiker from 'components/elements/BusBiker'
import Services from 'components/elements/Services'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const CustomTitle = styled.div``

const Content = styled(ParseContent)`
  h2 {
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.xxml};
    margin-bottom: 20px;
    position: relative;

    @media screen and (max-width: 576px) {
      padding-bottom: 30px;
    }

    &::before {
      content: "";
      position: absolute;
      right: -1230px;
      width: 2000px;
      height: 5px;
      bottom: 20px;
      background-color: ${props => props.theme.color.face.main};

      @media screen and (max-width: 1199px) {
        right: -1400px;
      }

      @media screen and (max-width: 991px) {
        right: -1650px;
      }

      @media screen and (max-width: 768px) {
        right: -1800px;
      }


      @media screen and (max-width: 576px) {
        right: -1000px;
      }
    }
  }

  p {
    line-height: 30px;
  }
`

const HomeTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout activePage={path}>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <HeroDefault fields={acf.banner} />

      <div className="my-lg-5" />
      
      <CustomTitle className="container py-lg-5 py-3">
        <div className="col-lg-12">
          <Content content={acf.services.description} />
        </div>
        <Services className="mt-5" />
      </CustomTitle>

      <MoreAbout data={acf.usps} />

      <Innovation fields={acf.innovation} />

      {/* <BusBiker fields={acf.busbiker} /> */}

      {/* <div className="my-5 py-1 py-lg-5" /> */}

    </Layout>
  )
}

export default HomeTemplate