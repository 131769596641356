import React from 'react'

// Third Party
import styled from 'styled-components'

// Components
import ImageBackground from 'components/shared/ImageBackground'
import ParseContent from 'components/shared/ParseContent'
import CustomLink from 'components/shared/CustomLink'

// Images
import Icon from 'img/icon-right.svg'
import Icon2 from 'img/icon-right-white.svg'


const Ul = styled.ul`
  padding-left: 5px;
`

const Li = styled.li`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
`

export default function Service({ title, path, acf }) {
  return (
    <Container src={acf.preview.image} style={{ backgroundSize: 'cover' }}>
      <Overlay className="d-flex flex-column justify-content-between">
        <div>
          <Ul>
            {acf.preview.usps.map(({ title }) => {
              return <Li key={title}>{title}</Li>
            })}
          </Ul>
        </div>
        <Title className="d-flex justify-content-end">
          <CustomLink className="mr-2 text-white" to={path}>
            {<ParseContent content={title} />}
          </CustomLink>
          <img src={Icon2} alt="arrow" />
        </Title>
      </Overlay>
      <Background className="d-flex flex-column justify-content-end">
        <Title className="d-flex justify-content-end">
          <span className="mr-2">{<ParseContent content={title} />}</span>
          <img src={Icon} alt="arrow" />
        </Title>
      </Background>
    </Container>
  )
}

const Container = styled(ImageBackground)`
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.color.text.main};
  position: relative;
  height: 220px;
  width: 320px;
  margin: 20px 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  position: relative;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 20px auto;
  }
`
const Background = styled.div`
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.9) 30%,
    transparent
  );
  padding: 30px 24px;
  width: 100%;
  height: 100%;
`
const Title = styled.div`
  font-family: ${(props) => props.theme.font.family.main};
  font-weight: ${(props) => props.theme.font.weight.l};
  font-size: ${(props) => props.theme.font.size.xsm};
  color: ${(props) => props.theme.color.text.secondary};
  text-shadow: 0 0 10px #FFFFFF;
`
const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.color.face.main};
  color: ${(props) => props.theme.color.text.light};
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms ease-in;
  position: absolute;
  font-family: ${(props) => props.theme.font.family.main};
  font-weight: ${(props) => props.theme.font.weight.m};
  font-size: ${(props) => props.theme.font.size.m};
  line-height: ${(props) => props.theme.font.size.xl};
  & > div:first-child {
    margin: 30px 12px 0 12px;
  }
  & > div:last-child {
    margin: 0px 24px 30px 24px;
  }
  ${Container}:hover & {
    max-height: 220px;
  }

  & ${Title} {
    text-shadow: none !important;
  }
`